@import "https://fonts.googleapis.com/css2?family=Carattere&family=Cardo:ital,wght@0,400;0,700;1,400&family=Cedarville+Cursive&family=Italiana&display=swap";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* https://tailwindcss.com/docs/font-family#customizing-the-default-font */
@layer base {
  html {
    font-family: "Cardo", serif;
  }
  body {
    @apply bg-primary-50 text-primary-700 dark:bg-stone-900 dark:text-primary-100;
  }
  main {
    @apply bg-primary-50 dark:bg-stone-900 flex-grow;
  }
  section {
    @apply bg-primary-50 dark:bg-stone-900;
  }
  footer {
    @apply bg-primary-50 m-4 dark:bg-stone-900;
  }
  h1 {
    @apply mb-4 font-extrabold tracking-tight leading-none sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl text-primary-700 dark:text-primary-100;
  }
  h2 {
    @apply mb-4 font-extrabold tracking-tight leading-none sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-primary-700 dark:text-primary-100;
  }
  h3 {
    @apply mb-4 font-bold tracking-tight leading-none sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-primary-700 dark:text-primary-100;
  }
  h4 {
    @apply mb-4 font-bold tracking-tight leading-none sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-primary-700 dark:text-primary-100;
  }
  h5 {
    @apply mb-4 font-bold tracking-tight leading-none sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-primary-700 dark:text-primary-100;
  }
  h6 {
    @apply mb-4 font-bold tracking-tight leading-none sm:text-base md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-primary-700 dark:text-primary-100;
  }
  p {
    @apply mb-2 font-normal md:text-base lg:text-lg xl:text-xl text-primary-800 dark:text-primary-100;
  }
  span {
    @apply font-normal md:text-base lg:text-lg xl:text-xl text-primary-800 dark:text-primary-100;
  }
  a {
    @apply md:text-base lg:text-lg xl:text-xl md:font-medium xl:font-bold text-blue-600 dark:text-blue-500;
  }
  label {
    @apply mb-2 text-base font-medium text-primary-900 dark:text-primary-100;
  }
  input {
    @apply bg-gray-50 border border-gray-300 text-primary-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-primary-100 dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }
  hr.solid {
    /* border-top: 1px solid #dbe3d5; */
    @apply border border-primary-100 dark:border-stone-800;
  }
}

@layer components {
  .btn {
    @apply items-center inline-block font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white dark:text-primary-100;
  }
  .btn-sm {
    @apply px-3 py-2 text-sm;
  }
  .btn-md {
    @apply px-4 py-2 text-base;
  }
  .btn-lg {
    @apply px-5 py-2.5;
  }
  .btn-xl {
    @apply px-6 py-3 text-lg;
  }
  .btn-wide {
    @apply px-20 py-3 text-lg;
  }
  .btn-full-width {
    @apply w-full py-2.5 sm:text-sm md:text-base lg:text-lg;
  }
  .btn-bg-primary {
    @apply btn bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-600;
  }
  .btn-bg-secondary {
    @apply btn bg-secondary-600 hover:bg-secondary-700 focus:ring-secondary-500 dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-secondary-600;
  }
  .btn-bg-blue {
    @apply btn bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-600;
  }
  .btn-bg-success {
    @apply btn bg-green-600 hover:bg-green-700 focus:ring-green-500 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-600;
  }
  .btn-bg-danger {
    @apply btn bg-red-600 hover:bg-red-700 focus:ring-red-500 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-600;
  }
  .btn-bg-warning {
    @apply btn bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-600;
  }
  .btn-bg-info {
    @apply btn bg-violet-600 hover:bg-violet-700 focus:ring-violet-500 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-600;
  }
  .btn-bg-disabled {
    @apply bg-gray-400 cursor-not-allowed btn hover:bg-gray-500 focus:ring-gray-300 dark:bg-gray-400 dark:hover:bg-gray-500 dark:focus:ring-gray-600;
  }
  .nav-link {
    @apply block py-2 px-3 text-primary-700 dark:text-primary-100 hover:underline;
  }
  .active-nav {
    @apply block py-2 px-3 text-primary-100 bg-primary-700 rounded dark:text-primary-100;
  }
  .section-unauth {
    @apply bg-primary-50;
  }
}
